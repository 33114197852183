const bottomForm = document.querySelector('section.bottom-form');

if(bottomForm) {
    let selections = bottomForm.querySelectorAll('input[type="radio"]');
    selections.forEach(element => {
        element.addEventListener('click', function(e) {
            bottomForm.querySelector('.comment-box').classList.add('show');
            if(element.value == 'Ja') {
                if(bottomForm.querySelector('.comment-answer-input.input-no')) {
                    bottomForm.querySelector('.comment-answer-input.input-no').remove();
                }
                if(!bottomForm.querySelector('.comment-answer-input.input-yes')) {
                    bottomForm.querySelector('form').innerHTML += '<input class="comment-answer-input input-yes" name="comment_answer_yes" value="Ja" type="hidden"></input>';
                }
                bottomForm.querySelector('.comment-form textarea').setAttribute('placeholder', 'Så bra! Hva så du etter? En tilbakemelding hjelper oss med å lage bedre nettsider.');
            }
            else {
                if(bottomForm.querySelector('.comment-answer-input.input-yes')) {
                    bottomForm.querySelector('.comment-answer-input.input-yes').remove();
                }
                if(!bottomForm.querySelector('.comment-answer-input.input-no')) {
                    bottomForm.querySelector('form').innerHTML += '<input class="comment-answer-input input-no" name="comment_answer_no" value="Nei" type="hidden"></input>';
                }
                bottomForm.querySelector('.comment-form textarea').setAttribute('placeholder', 'En tilbakemelding fra deg hjelper oss med å lage bedre nettsider.');
            }
        });
    });

    bottomForm.querySelector('form').addEventListener('submit', function(e) {
        let selectedValue = bottomForm.querySelector('input.comment-answer-input').value;
        if (window.dataLayer) {
            window.dataLayer.push({
                'event': 'form_submit',
                'eventModel': {
                    'form_id': "feedback-comment-form",
                    'event_callback': "Function",
                    'fant_du_svar': selectedValue
                },
                'eventCallback': "Function",
                'gtm.uniqueEventId': 2,
                'gtm.priorityId': 10
            });
        }
    });
}